import decode from "jwt-decode";
import Cookies from "vue-cookies";
// import { ID_EV_ALERTS } from "@/core/utils/constants";

const getDefaultState = () => {
  return {
    access: null,
    refresh: null,
    user: null,
    modules: null,
    breadcrumb: null,
    notifications: null,
  };
};

const state = getDefaultState;

const mutations = {
  setSessionAccess(state, access) {
    state.access = access;
  },
  setSessionRefresh(state, refresh) {
    state.refresh = refresh;
  },
  setUser(state, user) {
    state.user = user;
  },
  setNotifications(state, notifications) {
    state.notifications = notifications;
  },
  setModules(state, modules) {
    state.modules = modules;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setBreadcrumb(state, breadcrumb) {
    state.breadcrumb = breadcrumb;
  },
};
const actions = {
  async checkSession({ dispatch, state, commit }) {
    let acc = Cookies.get("acc");
    let ref = Cookies.get("ref");
    if (!acc) return;
    try {
      decode(acc);
      if (acc && !state.user) {
        commit("setSessionAccess", acc);
        commit("setSessionRefresh", ref);
        await dispatch("loadUser");
      }
    } catch (e) {
      Cookies.remove("acc");
      return;
    }
  },
  async login({ dispatch, commit, state }, user) {
    let credentials = {
      username: user.email,
      password: user.password,
    };
    let { data, error } = await this._vm.$api.login(credentials);
    if (error) {
      throw error;
    }
    
    commit("setSessionAccess", data.access);
    commit("setSessionRefresh", data.refresh);
    Cookies.set("acc", data.access, "25MIN");
    Cookies.set("ref", data.refresh, "25MIN");
    return await dispatch("loadUser");
  },
  async twoAuthenticated({ dispatch, commit, state }) {
    // const { user: { email, phone } } = state;
    // console.log({ email, phone });
    return await state
  },
  async loadUser({ commit, dispatch, state }) {
    try {
      let userId = decode(state.access).user_id;
      let { data, error } = await this._vm.$api.getUser(userId);
      if (error) {
        Cookies.remove("acc");
        return;
      }
      commit("setUser", data);
      if (data.is_active) {
        if (data.person.position) {
          dispatch(
            "form/loadDashboardOptions",
            { access: state.access },
            { root: true }
          );
          dispatch("fetchModules");
        } else {
          // Se deshabilita esta opción
          // dispatch('complaints/loadComplaints', null, {root:true})
        }
        dispatch(
          "form/loadComplaintOptions",
          { access: state.access },
          { root: true }
        );
      }
      return data;
    } catch (error) {
      return;
    }
  },
  async loadPersonalData({ commit, dispatch, state }) {
    let userId = decode(state.access).user_id;
    let { data, error } = await this._vm.$api.getUser(userId);
    if (error) {
      Cookies.remove("acc");
      return;
    }
    commit("setUser", data);
  },
  async logout({ commit }) {
    let {error, data} = await this._vm.$api.logout()
    if(data) {
      commit("resetState");
      Cookies.remove("acc");
    }
    if(error)
      console.error(error)
  },
  async fetchModules({ commit, dispatch }) {
    let { data, error } = await this._vm.$api.getModules();
    if (error) {
      this._vm.$notification.error({
        message: "Error",
        description:
          "Hubo un error en la carga de los modulos, recarga la página",
      });
      return;
    }
    if (data) {
      commit("setModules", data.results);
      let havePermissions = data.results.filter((module) => module.id == 28);
      if (havePermissions.length) {
        dispatch("getNotifications");
      }
      //notification entidad vigilada
      // let havePermissionsNotificationEv = data.results.filter((module) => module.name == ID_EV_ALERTS);
      // if (havePermissionsNotificationEv.length) {
      //   dispatch("getNotificationsEv");
      // }
    }
  },
  async getNotifications({ commit, state }) {
    let { data } = await this._vm.$api.getNotViewdAlert();
    if (data) {
      let count = data.count;
      let lastNotifications = data.results;
      commit("setNotifications", { lastNotifications, count });
    }
  },
  async getNotificationsCustomer({commit, state}){
     let { data } = await this._vm.$api.getComplaintsNoRated();
    if (data) {
      let count = data.size_unqualifieds;
      let lastNotifications = data.unqualifieds;
      commit("setNotifications", { lastNotifications, count });
    }
  },
  async getNotificationsEv({ commit, state }) {
    let { data } = await this._vm.$api.getNotViewdAlertEv();
    if (data) {
      let count = data.count;
      let lastNotifications = data.results;
      commit("setNotifications", { lastNotifications, count });
    }
  },
  updateBreadcrumb({ commit }, breadcrumb) {
    commit("setBreadcrumb", breadcrumb);
  },
};
const getters = {
  isAuthenticated: (state) => {
    return state.access != null ? true : false;
  },
  isActive: (state) => {
    return state.user ? state.user.is_active : false;
  },
  getRole: (state) => {
    let rol = state.user?.person?.position;
    return rol;
  },
  getBreadcrumb: (state) => {
    return state.breadcrumb;
  },
};

const session = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default session;
