import { initializeApp, getApps } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyCtyDthphoCmr5bOz878-VMcSsuG21BbsU",
    authDomain: "sfc-smartsupervision-qa.firebaseapp.com",
    projectId: "sfc-smartsupervision-qa",
    storageBucket: "sfc-smartsupervision-qa.firebasestorage.app",
    messagingSenderId: "469609491334",
    appId: "1:469609491334:web:e75ce085f175942f2c5a07"
};

// Inicializa Firebase si no hay ninguna app inicializada
export const app = !getApps().length ? initializeApp(firebaseConfig) : getApps()[0];
// Obtener la instancia de autenticación
export const auth = getAuth(app);