export default [
    {
        path: '/login',
        component: ()=> import('@/layouts/FullWidthLayout.vue'),
        children: [
            {
                path: '',
                component: () => import('@/views/login/Login.vue')
            },
            {
                path: 'verify-code',
                component: () => import('@/views/login/VerifyCode.vue')
            }
        ]
    },
    {
        path: '/signup',
        component: ()=> import('@/layouts/FullWidthLayout.vue'),
        children: [
            {
                path: '',
                component: () => import('@/views/login/Signup.vue')
            }
        ]
    },
    {
        path: '/logout',
        component: ()=> import('@/layouts/FullWidthLayout.vue'),
        children: [
            {
                path: '',
                component: () => import('@/views/login/Logout.vue')
            }
        ]
    },
    {
        path: '/verify',
        component: ()=> import('@/layouts/FullWidthLayout.vue'),
        children: [
            {
                path: '',
                name: 'Verify',
                props: true,
                component: () => import('@/views/login/Verify.vue'),
            }
        ]
    },
    {
        path: '/recovery',
        component: ()=> import('@/layouts/FullWidthLayout.vue'),
        children: [
            {
                path: '',
                component: () => import('@/views/login/Recovery.vue'),
                meta: {
                    breadcrumb: [
                        {label: 'Inicio', link: '/'},
                        {label: 'Restablecer contraseña'}
                    ]
                },
            },
            {
                path: 'restore',
                component: () => import('@/views/login/Recovery.vue'),
                meta: {
                    breadcrumb: [
                        {label: 'Inicio', link: '/'},
                        {label: 'Restablecer contraseña'}
                    ]
                }
            }
        ]
    }
]