<template>
    <div class="">
        <h3 class="text-lg sm:text-lg text-blue-dark font-bold">Ingresar código de autenticación.</h3>
        <h3 class="text-lg sm:text-lg">{{ label }}</h3>
        <a-form-model layout="vertical">
            <a-input class="mt-5" :placeholder=placeholderInput autocomplete="" v-model="phoneNumber"
                ref='phoneNumber' />
            <button id="sign-in-button" @click="sendVerificationCode">Send Code</button>
        </a-form-model>
    </div>
</template>

<script>

import { RecaptchaVerifier, PhoneAuthProvider, signInWithCredential, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../firebase/config";

export default {
    props: ['label'],
    data() {
        return {
            phoneNumber: '',
            verificationCode: '',
            placeholderInput: 'Ingrese aquí el código'
        };
    },
    created() {
    },
    components: {
    },
    methods: {
        async sendVerificationCode() {

            this.error = null;
            try {
                window.recaptchaVerifier = new RecaptchaVerifier(auth, 'sign-in-button', {
                    'size': 'invisible',
                    'callback': (response) => {
                        // reCAPTCHA solved, allow signInWithPhoneNumber.
                        onSignInSubmit();
                    }
                });
                var appVerifier = window.recaptchaVerifier;
                signInWithPhoneNumber(auth, this.phoneNumber, appVerifier)
                    .then(function (confirmationResult) {
                        console.log(confirmationResult);
                        window.confirmationResult = confirmationResult
                    })
                    .catch(function (error) {
                        console.log(error)
                    });

                ///////////////////////////////


                // const recaptchaVerifier = new RecaptchaVerifier(auth, 'sign-in-button', {
                //     'size': 'invisible',
                //     'callback': (response) => {
                //         // reCAPTCHA solved, allow signInWithPhoneNumber.
                //         onSignInSubmit();
                //     }
                // });

                // signInWithPhoneNumber(auth, this.phoneNumber, recaptchaVerifier)
                //     .then((confirmationResult) => {
                //         // SMS sent. Prompt user to type the code from the message, then sign the
                //         // user in with confirmationResult.confirm(code).
                //         console.log(confirmationResult);

                //         window.confirmationResult = confirmationResult;
                //         // ...
                //     }).catch((error) => {
                //         console.log(error);

                //         // Error; SMS not sent
                //         // ...
                //     });

                // await recaptchaVerifier.render();
                // const phoneProvider = new PhoneAuthProvider(auth);
                // this.verificationId = await phoneProvider.verifyPhoneNumber(this.phoneNumber, recaptchaVerifier);
                // this.verificationCodeSent = true;
                // console.log("Verification ID:", this.verificationId);


            } catch (err) {
                console.log(err);
                this.error = err.message;
            }
        },

        async verifyCode() {
            this.error = null;
            try {
                if (!this.verificationCode) throw new Error("Please enter the verification code.");
                const credential = PhoneAuthProvider.credential(this.verificationId, this.verificationCode);
                const userCredential = await signInWithCredential(auth, credential);
                this.user = userCredential.user;
                this.verificationCodeSent = false;
                console.log("User authenticated:", this.user.phoneNumber);
            } catch (err) {
                console.error("Error al verificar el código:", err);
                this.error = err.message;
            }
        }
    },
    computed: {
    }
}
</script>

<style></style>